import { z } from 'zod';
import type { Configuration } from '@happykit/flags/config';

// You can replace this with your exact flag types
export type AppFlags = {
  useEmbeddedLoginFlow: boolean | null;
  sendSmsDirectlyByTwilio: boolean | null;
  enableOrderTypes: boolean | null;
  enableFeed: boolean | null;
  enableSocialOrders: boolean | null;
  enableBulkOrders: boolean;
  pauseBulkOrderImage: 'thanks-giving' | 'winter' | 'not-supported';
  sendNotifications: 'SMS' | 'FCM';
  enableAdminValidation: boolean;
  showDropofflocations: boolean;
  useCourier: boolean;
  enableSquare: boolean;
  enableUnderConstructionMode: boolean;
};

const envKey = z.string().parse(process.env.NEXT_PUBLIC_FLAGS_ENV_KEY);

export const config: Configuration<AppFlags> = {
  envKey,
  //   envKey: process.env.NEXT_PUBLIC_FLAGS_ENV_KEY!,

  // You can provide defaults flag values here
  defaultFlags: {
    useEmbeddedLoginFlow: false,
    sendSmsDirectlyByTwilio: false,
    enableOrderTypes: false,
    enableFeed: false,
    enableSocialOrders: false,
    enableBulkOrders: true,
    pauseBulkOrderImage: 'winter',
    sendNotifications: 'SMS',
    enableAdminValidation: true,
    showDropofflocations: false,
    useCourier: false,
    enableSquare: true,
    enableUnderConstructionMode: true,
  },
};
