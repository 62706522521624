import { ComponentStyleConfig, extendTheme } from '@chakra-ui/react';
import { FormErrorMessage } from '@chakra-ui/form-control';

const colors = {
  brand: {
    900: '#1a365d',
    800: '#153e75',
    700: '#2a69ac',
    bg: '#FFF8F5',
  },
  brown: {
    1: '#401605',
    2: '#705043',
    3: '#9F8A82',
    4: '#CFC5C0',
    5: '#E7E2E0',
  },
  white: { 1: '#FFF8F5' },
  orange: { 1: '#FF5714', 2: '#A8390D' },
  yellow: { 1: '#FFCC14', 2: '#A8870D' },
  red: { 2: '#FFC6BD' },
  green: { 1: '#64F2B0' },
};

const breakpoints = {
  sm: '20em',
  md: '48em',
  lg: '62em',
  xl: '80em',
};

const fontSizes = {
  h1: '60px',
  h2: '45px',
  h3: '30px',
  h4: '25px',
  h5: '20px',

  dh1: '60px',
  dh2: '48px',
  dh3: '36px',
  dh4: '20px',
  dh5: '16px',

  p1: '30px',
  p2: '25px',
  p3: '20px',
  p4: '15px',
  p5: '10px',
  p6: '12px',

  dp1: '24px',
  dp2: '20px',
  dp3: '16px',
  dp4: '12px',
  dp5: '8px',
  dp6: '10px',
};

const fontWeights = {
  heading: 700,
  text: 400,
};

const textStyles = {
  h1: {
    fontSize: ['h1', 'h1', 'dh1', 'dh1'],
    fontFamily: 'heading',
    lineHeight: '90%',
    letterSpacing: 'tighter',
    fontWeight: 'bold',
  },
  h2: {
    fontSize: ['h2', 'h2', 'dh2', 'dh2'],
    fontFamily: 'heading',
    lineHeight: '80%',
    letterSpacing: 'tighter',
    fontWeight: 'bold',
  },
  h3: {
    fontSize: ['h3', 'h3', 'dh3', 'dh3'],
    fontFamily: 'heading',
    lineHeight: '100%',
    letterSpacing: 'tighter',
    fontWeight: 'bold',
  },
  h4: {
    fontSize: ['h4', 'h4', 'dh4', 'dh4'],
    fontFamily: 'heading',
    lineHeight: '100%',
    letterSpacing: 'tighter',
    fontWeight: 'bold',
  },
  h5: {
    fontSize: ['h5', 'h5', 'dh5', 'dh5'],
    fontFamily: 'heading',
    lineHeight: '100%',
    letterSpacing: 'tighter',
    fontWeight: 'bold',
  },

  p1: {
    fontSize: ['p1', 'p1', 'dp1', 'dp1'],
    fontFamily: 'body',
    lineHeight: '100%',
    letterSpacing: 'tighter',
  },
  p2: {
    fontSize: ['p2', 'p2', 'dp2', 'dp2'],
    fontFamily: 'body',
    lineHeight: '100%',
    letterSpacing: 'tighter',
  },
  p3: {
    fontSize: ['p3', 'p3', 'dp3', 'dp3'],
    fontFamily: 'body',
    lineHeight: '110%',
    letterSpacing: 'tighter',
  },
  p4: {
    fontSize: ['p4', 'p4', 'dp4', 'dp4'],
    fontFamily: 'body',
    lineHeight: '100%',
    letterSpacing: 'tighter',
  },
  p5: {
    fontSize: ['p5', 'p5', 'dp5', 'dp5'],
    fontFamily: 'body',
    lineHeight: '100%',
    letterSpacing: 'tighter',
  },
  p6: {
    fontSize: ['p6', 'p6', 'dp6', 'dp6'],
    fontFamily: 'body',
    lineHeight: '100%',
    letterSpacing: 'tighter',
  },
};

const inputStyle = {
  bg: 'brown.5',
  // borderColor: 'brown.1',
  color: 'brown.1',
  fontSize: 'p3',
  _placeholder: { opacity: 1, color: 'brown.4' },
  _invalid: {
    bg: 'red.2 !important',
    borderColor: 'red.500 !important',
  },
  _focus: { borderColor: 'brown.1', bg: 'brown.5' },
  _disabled: {
    opacity: 0.5,
  },
};

export const theme = extendTheme({
  colors,
  fontSizes,
  fontWeights,
  textStyles,
  breakpoints,
  fonts: {
    heading:
      'Work Sans Bold,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    body: 'Work Sans,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
  },
  components: {
    FormLabel: {
      baseStyle: {
        fontSize: 'p5',
        color: 'brown.1',
        mb: 1,
      },
    },
    Button: {
      variants: {
        _disabled: {
          bg: 'orange.1',
          pointerEvents: 'none',
          opacity: 0.5,
          _hover: {
            bg: 'orange.1',
            opacity: 0.5,
          },
        },
        brand: {
          bg: 'orange.1',
          color: 'white.1',
          borderRadius: 'base',
          fontSize: 'p3',
          fontWeight: 'bold',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
          // textShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          py: 2,
          px: 4,
          _hover: {
            background: 'orange.1',
            color: 'white.1',
            _disabled: {
              bg: 'orange.1',
            },
          },
          _active: {
            background: 'orange.1',
            color: 'white.1',
          },
          _focusVisible: {
            boxShadow: '0 0 0 3px rgba(64, 22, 5, 1)',
          },
        },
        secondary: {
          bg: 'brown.3',
          color: 'white.1',
          borderRadius: 'base',
          fontSize: 'p3',
          fontWeight: 'bold',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
          // textShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          py: 2,
          px: 4,
          _hover: {
            background: 'brown.3',
            color: 'white.1',
            _disabled: {
              bg: 'brown.3',
            },
          },
          _active: {
            background: 'brown.3',
            color: 'white.1',
          },
          _focusVisible: {
            boxShadow: '0 0 0 3px rgba(64, 22, 5, 1)',
          },
        },
      },
      defaultProps: {
        variant: 'brand',
      },
    },
    Select: {
      variants: {
        brand: {
          field: inputStyle,
          icon: { w: 4, h: 4, fill: 'brown.1' },
        },
        building: {
          field: { ...inputStyle, pl: 10 },
          icon: { w: 4, h: 4, fill: 'brown.1' },
        },
      },
      defaultProps: {
        variant: 'brand',
      },
    },
    Input: {
      variants: {
        filled: {
          field: inputStyle,
        },
      },
      defaultProps: {
        variant: 'filled',
      },
    },
    Textarea: {
      variants: {
        filled: {
          field: inputStyle,
        },
      },
      defaultProps: {
        variant: 'filled',
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          color: 'brown.1',
          borderColor: 'brown.1',
          _checked: {
            bg: 'brown.1',
            borderColor: 'brown.1',
          },
        },
      },
    },
    Radio: {
      variants: {
        primary: ({ colorScheme = 'primary' }) => ({
          color: 'brown.1',
          control: {
            w: 5,
            h: 5,
            _checked: {
              color: 'brown.1',
            },
          },
          label: {
            fontSize: 20,
            color: 'brown.1',
          },
          container: {
            color: 'brown.1',
            borderColor: 'brown.1',
          },
        }),
      },
      defaultProps: {
        variant: 'primary',
        colorScheme: 'primary',
      },
    },
    // Modal: {
    //   baseStyle: (props: any) => ({
    //     dialog: {
    //       borderRadius: 20,
    //     },
    //     content: {
    //       borderRadius: 20,
    //     }
    //   }),
    // },
  },
  //   Text: {
  //     baseStyle: {
  //       fontWeight: 'text',
  //       lineHeight: '100%',
  //       letterSpacing: 'tighter',
  //     },
  //   },
  //   Heading: {
  //     baseStyle: {
  //       fontWeight: 'heading',
  //       lineHeight: '90%',
  //       letterSpacing: 'tighter',
  //     },
  //   }
  // },
});
