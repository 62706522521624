import React, { useRef, useState } from 'react';
import {
  Button as ChakraButton,
  Flex,
  IconButton,
  Box,
  Button,
  Avatar,
} from '@chakra-ui/react';

import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import NextLink from 'next/link';
import { Header, Text } from '../Common/Brand';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import Logo from '../Common/Logo';
import { UserProfileClientAPI } from '../../db/types-client-api';
import { getStoragePublicUrl } from '../../utils/storage';
import { IconUserProfile } from '../Common/BrandIcons';
import { useFlagBag } from 'flags/client';

interface Props {
  loadingUserProfile: boolean;
  userProfile: UserProfileClientAPI | null;
  onSignUp: () => void;
  onLogout: () => void;
}

export const menuItems = [
  { title: 'Home', href: '/' },
  { title: 'Deals', href: '/deals' },
  {
    title: 'Orders',
    href: '/orders',
    flag: 'enableSocialOrders' as const,
  },
  {
    title: 'Feed',
    href: '/feed',
    needUser: true,
    flag: 'enableFeed' as const,
  },
  { title: 'FAQ', href: '/faq' },
  { title: 'Contact', href: '/contact' },
  { title: 'Account', href: '/account', needUser: true, mobileOnly: true },
];

export const userMenuItems = [
  { title: 'Home', href: '/' },
  { title: 'Deals', href: '/deals' },
  {
    title: 'Orders',
    href: '/orders',
    flag: 'enableSocialOrders' as const,
  },
  {
    title: 'Feed',
    href: '/feed',
    needUser: true,
    flag: 'enableFeed' as const,
  },
  { title: 'FAQ', href: '/faq' },
  { title: 'Contact', href: '/contact' },
  { title: 'Account', href: '/account', needUser: true, mobileOnly: true },
];

const bg = 'brown.1';

export const buttonColors = {
  bg: bg,
  color: 'white.1',
  _hover: {
    background: { bg },
  },
  _active: {
    background: { bg },
  },
};

type DisplayOption = 'none' | 'menu' | 'user-menu';

const TopMenu = ({
  loadingUserProfile,
  userProfile,
  onSignUp,
  onLogout,
}: Props) => {
  const { flags } = useFlagBag();
  const [display, changeDisplay] = useState<DisplayOption>('none');
  const lowPassClick = useRef(0);
  const ref1 = useRef<HTMLHeadingElement>(null);
  const ref2 = useRef<HTMLHeadingElement>(null);
  useOnClickOutside(ref1, () => {
    // console.log(`muly:useOnClickOutside ref1 ${display}`, { ref1 });
    if (display === 'user-menu') {
      changeDisplay('none');
      lowPassClick.current = Date.now();
    }
  });
  useOnClickOutside(ref2, () => {
    // console.log(`muly:useOnClickOutside ref2 ${display}`, { ref2 });
    if (display === 'menu') {
      changeDisplay('none');
      lowPassClick.current = Date.now();
    }
  });

  const showMenu = true;

  // console.log(`muly:TopMenu flags`, { flags, userProfile });

  return (
    <Box overflowX="hidden" w="100%" display={'block'}>
      <Flex
        w="100%"
        alignItems="center"
        px={5}
        pt="43px"
        pb="23px"
        maxWidth="7xl"
        m="auto"
      >
        <NextLink href={'/'} passHref>
          <Box as="a" flexGrow={1}>
            <Logo />
          </Box>
        </NextLink>
        {/* Desktop */}
        <Flex
          display={['none', 'none', 'none', showMenu ? 'flex' : 'none']}
          mr={5}
          gap={2}
          flexShrink={1}
          id="top-menu-desktop"
        >
          {userProfile &&
            menuItems
              .filter(
                ({ mobileOnly, flag }) =>
                  (typeof mobileOnly === 'undefined' || !mobileOnly) &&
                  (!flag || (flags ? flags[flag] : false))
              )
              .map(({ title, href, needUser }) => (
                <NextLink href={href} passHref key={title}>
                  <ChakraButton
                    as="a"
                    variant="ghost"
                    aria-label={title}
                    w="100%"
                    isDisabled={needUser && !userProfile}
                    {...buttonColors}
                  >
                    <Text size="p3">{title}</Text>
                  </ChakraButton>
                </NextLink>
              ))}
        </Flex>

        <Flex direction="row" alignItems="center">
          {/*{!userProfile && (*/}
          {/*  <Button onClick={() => onSignUp()} id="sign-up">*/}
          {/*    Sign Up*/}
          {/*  </Button>*/}
          {/*)}*/}

          {!!userProfile && (
            <Box
              position="relative"
              onClick={(e) => {
                console.log(
                  `muly:click user menu ${display} ${
                    Date.now() - lowPassClick.current
                  }`,
                  {}
                );
                if (Date.now() - lowPassClick.current > 500) {
                  changeDisplay('user-menu');
                }
              }}
              id="account-menu"
            >
              {!!userProfile.avatar_url && (
                <Avatar src={getStoragePublicUrl(userProfile.avatar_url)} />
              )}
              {!userProfile.avatar_url && (
                <IconUserProfile w={12} h={12} fill="white.1" />
              )}

              {/* User Account Menu */}
              <Flex
                position="fixed"
                ref={ref1}
                mt={7}
                // ml="-38%"
                py={3}
                px={3}
                minW={72}
                display={display === 'user-menu' ? 'flex' : 'none'}
                bgColor={bg}
                zIndex={20}
                // pos="fixed"
                top="20"
                right="calc((100% - min(100%, var(--chakra-sizes-7xl)))/2)"
                overflowY="auto"
                flexDir="column"
                alignItems="start"
                // borderRadius="lg"
                // borderColor="orange.1"
                // borderWidth={2}
              >
                <Flex flexDir="column" align="start" width="100%" gap={3}>
                  {userMenuItems
                    .filter(
                      ({ flag }) => !flag || (flags ? flags[flag] : false)
                    )
                    .map(({ title, href, mobileOnly }) => (
                      <NextLink href={href} passHref key={title}>
                        <ChakraButton
                          display={[
                            'block',
                            'block',
                            'block',
                            mobileOnly ? 'block' : 'none',
                          ]}
                          onClick={() => {
                            lowPassClick.current = Date.now();
                            console.log('muly:Link ChakraButton click', {});
                            changeDisplay('none');
                          }}
                          fontSize="sm"
                          justifyContent="start"
                          as="a"
                          variant="ghost"
                          aria-label="Index"
                          // w="100%"
                          {...buttonColors}
                        >
                          <Header size="h3">{title}</Header>
                        </ChakraButton>
                      </NextLink>
                    ))}
                  <Button
                    ml={5}
                    // alignSelf="stretch"
                    onClick={() => {
                      changeDisplay('none');
                      onLogout();
                    }}
                  >
                    Logout
                  </Button>
                  <Box mt="80px"></Box>
                </Flex>
              </Flex>
            </Box>
          )}

          {/*/!* Mobile *!/*/}
          {/*<IconButton*/}
          {/*  ml={2}*/}
          {/*  h="27px"*/}
          {/*  minW="27px"*/}
          {/*  w="17px"*/}
          {/*  aria-label="Open Menu"*/}
          {/*  icon={<HamburgerIcon w={6} h={6} />}*/}
          {/*  onClick={() => changeDisplay('menu')}*/}
          {/*  display={showMenu ? ['flex', 'flex', 'flex', 'none'] : 'none'}*/}
          {/*  {...buttonColors}*/}
          {/*/>*/}
        </Flex>
      </Flex>

      {/* Mobile Content */}
      {/*<Flex*/}
      {/*  ref={ref2}*/}
      {/*  mt={7}*/}
      {/*  minW={72}*/}
      {/*  display={display === 'menu' ? 'flex' : 'none'}*/}
      {/*  bgColor={bg}*/}
      {/*  zIndex={20}*/}
      {/*  pos="fixed"*/}
      {/*  top="0"*/}
      {/*  right="0"*/}
      {/*  overflowY="auto"*/}
      {/*  flexDir="column"*/}
      {/*  alignItems="start"*/}
      {/*>*/}
      {/*  <Flex justify="flex-end" alignSelf="end">*/}
      {/*    <IconButton*/}
      {/*      mt={2}*/}
      {/*      mr={2}*/}
      {/*      aria-label="Close Menu"*/}
      {/*      size="lg"*/}
      {/*      icon={<CloseIcon />}*/}
      {/*      onClick={() => changeDisplay('none')}*/}
      {/*      {...buttonColors}*/}
      {/*    />*/}
      {/*  </Flex>*/}

      {/*  <Flex flexDir="column" align="start">*/}
      {/*    {menuItems*/}
      {/*      .filter(({ flag }) => !flag || (flags ? flags[flag] : false))*/}
      {/*      .map(({ title, href, needUser }) => (*/}
      {/*        <NextLink href={href} passHref key={title}>*/}
      {/*          <ChakraButton*/}
      {/*            onClick={() => {*/}
      {/*              lowPassClick.current = Date.now();*/}
      {/*              changeDisplay('none');*/}
      {/*            }}*/}
      {/*            fontSize="sm"*/}
      {/*            justifyContent="start"*/}
      {/*            as="a"*/}
      {/*            variant="ghost"*/}
      {/*            aria-label="Index"*/}
      {/*            w="100%"*/}
      {/*            isDisabled={needUser && !userProfile}*/}
      {/*            {...buttonColors}*/}
      {/*          >*/}
      {/*            <Header size="h3">{title}</Header>*/}
      {/*          </ChakraButton>*/}
      {/*        </NextLink>*/}
      {/*      ))}*/}
      {/*    <Box mt="80px"></Box>*/}
      {/*  </Flex>*/}
      {/*</Flex>*/}
    </Box>
  );
};

export default TopMenu;
