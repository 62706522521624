import React from 'react';
import { Box, Container, Heading, Stack, Text } from '@chakra-ui/react';
import Logo from 'components/Common/Logo';
import NextLink from 'next/link';
import { Button as ChakraButton, ButtonProps } from '@chakra-ui/button';

interface PageLinkProps extends ButtonProps {
  href: string;
  ariaLabel: string;
}

function PageLink({ href, ariaLabel, ...props }: PageLinkProps) {
  return (
    <NextLink href={href} passHref>
      <ChakraButton
        px={2}
        as="a"
        variant="link"
        aria-label={ariaLabel}
        marginTop={'10px'}
        {...props}
      >
        <Text color="#9F8A82" fontSize={16}>
          {ariaLabel}
        </Text>
      </ChakraButton>
    </NextLink>
  );
}

function Footer() {
  return (
    <Box backgroundColor="#260D03" width="100%">
      <Container maxWidth="2150px">
        <Stack
          direction={['column', 'column', 'row']}
          justifyContent="space-between"
          paddingX="30px"
          paddingY="50px"
          width="100%"
        >
          <Box maxWidth={300}>
            <Logo />
            <Text color="#FFFFFF" marginLeft="10px" marginY="10px">
              No delivery fees. No service fees. No price markups. We do
              delivery better.
            </Text>
          </Box>
          <Stack
            direction={['column', 'column', 'row']}
            color="#FFFFFF"
            marginLeft="10px !important"
          >
            <Box maxWidth={200}>
              <Heading fontSize={20}>Contact</Heading>
              <Text color="#9F8A82" fontSize={16} marginY="10px">
                650-534-2071
              </Text>
              <Text color="#9F8A82" fontSize={16}>
                customerservice@ekatree.com
              </Text>
              <Text color="#9F8A82" fontSize={16} marginY="10px">
                251 Little Falls Drive, Wilmington, DE 19808
              </Text>
            </Box>
            <Box maxWidth={200} paddingTop={'25px'}>
              <PageLink
                isDisabled={false}
                ariaLabel="Terms & Conditions"
                href="/terms"
              />
              <PageLink
                isDisabled={false}
                ariaLabel="Privacy Policy"
                href="/privacy"
              />
              <Box display="none">
                {/* @ts-ignore */}
                <a href="#" onclick="Clym.showWidget('', '', event);">
                  Privacy Center
                </a>
                <a
                  href="#"
                  // @ts-ignore
                  onclick="Clym.showWidget('/requests/new/do_not_sell_my_information', '', event);"
                >
                  Do not sell or share my personal information
                </a>
              </Box>

              {/* <Text color="#9F8A82" fontSize={16} marginY="10px">
            Terms & Conditions
          </Text>
          <Text color="#9F8A82" fontSize={16}>
            Privacy Policy
          </Text> */}
            </Box>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}

export default Footer;
