import { Flex, Button, Spacer, Box } from '@chakra-ui/react';
import TopMenu from './TopMenu';
import { Action, ActionHandler } from '../misc/action-types';
// import Footer from './Footer';
import { ReactNode } from 'react';
import { UserProfileClientAPI } from '../../db/types-client-api';
import Footer from 'components/landing-page/footer';

interface Props {
  loadingUserProfile: boolean;
  userProfile: UserProfileClientAPI | null;
  onAction: ActionHandler;
  children: ReactNode | ReactNode[];
}

const AppLayout = ({
  loadingUserProfile,
  userProfile,
  onAction,
  children,
}: Props) => {
  return (
    <Box maxW="100w" overflowX="hidden">
      {location.pathname !== '/' && (
        <TopMenu
          loadingUserProfile={loadingUserProfile}
          onSignUp={() => onAction(Action.SignUp)}
          onLogout={() => onAction(Action.Logout)}
          userProfile={userProfile}
        />
      )}
      {children}
      {location.pathname !== '/' ? <Footer></Footer> : null}
    </Box>
  );
};

export default AppLayout;
