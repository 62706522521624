import { createContext, useContext } from 'react';
import {
  LocationClientAPI,
  UserProfileClientAPI,
} from '../db/types-client-api';
import { Action, ActionHandler } from '../components/misc/action-types';

export type RefetchQuery =
  | 'orders-my'
  | 'orders-social'
  | 'orders-bulk'
  | 'feed'
  | 'user-profile';

export type Refetch = (refetchQuery: RefetchQuery) => void;

export interface DBContextType {
  // openWizard: (mode: WizardMode, topic?: string) => void;
  loadingUserProfile: boolean;
  userProfile?: UserProfileClientAPI;
  refetch: Refetch;
  setRefetch: (refetchQuery: RefetchQuery, refetch?: () => void) => void;
  locations: LocationClientAPI[];
  onAction: ActionHandler;
}

const DBContextTypeDefaultValues: DBContextType = {
  // openWizard: (mode: WizardMode, topic?: string) => {},
  loadingUserProfile: true,
  userProfile: undefined,
  locations: [],
  onAction: async (action: Action, params?: any) => {
    throw new Error('Need to override DBContext types');
  },

  refetch: async (refetchQuery) => {
    console.log(`reload default value ${refetchQuery}`);
  },

  setRefetch: (refetchQuery, refetchFn) => {},
};

export const DBContext = createContext<DBContextType>(
  DBContextTypeDefaultValues
);

export function useDBContext() {
  return useContext(DBContext);
}
