import { ChakraProvider } from '@chakra-ui/react';
import type { AppProps } from 'next/app';

import { AppRouter } from './api/trpc/[trpc]';
import { withTRPC } from '@trpc/next';
import { SSRContext } from '../utils/trpc';
import { httpBatchLink } from '@trpc/client/links/httpBatchLink';
import { loggerLink } from '@trpc/client/links/loggerLink';

// https://github.com/supabase-community/auth-helpers/blob/e5dd556debd89f347c05ace6aa716d4e87cadf00/packages/nextjs/README.md
import { createBrowserSupabaseClient } from '@supabase/auth-helpers-nextjs';
import { SessionContextProvider, useUser } from '@supabase/auth-helpers-react';
import superjson from 'superjson';
import { theme } from '../components/theme';
import '../components/layout/react-tiny-fab/fab-styles.css';
import { AppComponent } from '../context/AppComponent';
import Head from 'next/head';
import '../styles/globals.css';
import { useEffect, useState } from 'react';
import { hotjar } from 'react-hotjar';
import { useFlags } from 'flags/client';
import { FlagBagProvider } from '@happykit/flags/context';

// initAuth();

function MyApp({ Component, pageProps }: AppProps) {
  const user = useUser();
  const showLayout = Component.displayName !== 'Admin';
  const [supabaseClient] = useState(() => createBrowserSupabaseClient());
  const flagBag = useFlags({ user: { key: user?.id || '' } });

  // console.log('muly:MyApp FLAGS', { flagBag, user });

  useEffect(() => {
    const hjid = 3152783;
    const hjsv = 6;
    hotjar.initialize(hjid, hjsv);
  }, []);

  // console.log(`muly:MyApp`, { Component, pageProps });
  if (showLayout) {
    return (
      <FlagBagProvider value={flagBag}>
        <ChakraProvider theme={theme}>
          <SessionContextProvider supabaseClient={supabaseClient}>
            <AppComponent>
              <Head>
                <title>Ekatree</title>
                <meta name="description" content="ekatree.com" />
                <link rel="icon" href="/favicon.ico" />
                <link rel="canonical" href="https://www.ekatree.com/" />
                <meta property="og:url" content="https://www.ekatree.com" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Ekatree" />
                <meta
                  property="og:description"
                  content="A better way to get food, groceries, rideshare and other services delivered right to your door."
                />
                <meta
                  property="og:image"
                  content="https://ekatree.com/assets/images/og-image.jpg"
                />

                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="ekatree.com" />
                <meta
                  property="twitter:url"
                  content="https://www.ekatree.com"
                />
                <meta name="twitter:title" content="Ekatree" />
                <meta
                  name="twitter:description"
                  content="A better way to get food, groceries, rideshare and other services delivered right to your door."
                />
                <meta
                  name="twitter:image"
                  content="https://ekatree.com/assets/images/og-image.jpg"
                />
                <script async src="https://widget.clym-sdk.net/blocking.js" />
                <script async>
                  {`
                  (function(d,s,i,w,o){
                var js,cjs=d.getElementsByTagName(s)[0];
                if(d.getElementById(i))return;
                js=d.createElement('script');
                js.id=i;
                js.src="https://widget.clym-sdk.net/clym.js";
                js.onload=function(){Clym&&Clym.load(i,w,o);};
                cjs.parentNode.insertBefore(js, cjs);
                }(document,'script','clym-privacy','164f2eb71b6c4f45b763d996c5ys3d10',{}));
                `}
                </script>
                <link
                  rel="preload"
                  href="/assets/fonts/work-sans.regular.woff2"
                  as="font"
                  type="font/woff2"
                  crossOrigin=""
                />
              </Head>

              <Component {...pageProps} />
            </AppComponent>
          </SessionContextProvider>
        </ChakraProvider>
      </FlagBagProvider>
    );
  } else {
    return (
      <SessionContextProvider supabaseClient={supabaseClient}>
        <Head>
          <title>Ekatree</title>
          <meta name="description" content="Admin ekatree.com" />
          <link rel="icon" href="/favicon.ico" />
        </Head>

        <Component {...pageProps} />
      </SessionContextProvider>
    );
  }
}

// export default MyApp;

export default withTRPC<AppRouter>({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  config() {
    /**
     * If you want to use SSR, you need to use the server's full URL
     * @link https://trpc.io/docs/ssr
     */

    const url = process.env.NEXT_PUBLIC_URL
      ? process.env.NEXT_PUBLIC_URL
      : 'http://localhost:3000';

    // console.log(`### muly:withTRPC config ${url}`, { env: process.env });

    return {
      /**
       * @link https://trpc.io/docs/links
       */
      links: [
        // adds pretty logs to your console in development and logs errors in production
        loggerLink({
          enabled: (opts) =>
            process.env.NODE_ENV === 'development' ||
            (opts.direction === 'down' && opts.result instanceof Error),
        }),
        httpBatchLink({
          url: `${url}/api/trpc`,
        }),
      ],
      /**
       * @link https://trpc.io/docs/data-transformers
       */
      transformer: superjson,
      /**
       * @link https://react-query.tanstack.com/reference/QueryClient
       */
      // queryClientConfig: { defaultOptions: { queries: { staleTime: 60 } } },
    };
  },
  /**
   * @link https://trpc.io/docs/ssr
   */
  ssr: true,
  /**
   * Set headers or status code when doing SSR
   */
  responseMeta(opts) {
    const ctx = opts.ctx as SSRContext;

    if (ctx.status) {
      // If HTTP status set, propagate that
      return {
        status: ctx.status,
      };
    }

    const error = opts.clientErrors[0];
    if (error) {
      // Propagate http first error from API calls
      return {
        status: error.data?.httpStatus ?? 500,
      };
    }
    // For app caching with SSR see https://trpc.io/docs/caching
    return {};
  },
})(MyApp);
