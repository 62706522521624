import { ReactNode, useEffect, useRef, useState } from 'react';
import { useSessionContext, useUser } from '@supabase/auth-helpers-react';
import { useRouter } from 'next/router';
import {
  missingUserProfileInfo,
  WizardMode,
} from '../components/wizard-flows/CreateOrderSignupWizard';
import { DBContext, DBContextType, RefetchQuery } from './dbContext';
import { useOpenWizardListener } from '../components/wizard-flows/useOpenWizardListener';
import { Action } from '../components/misc/action-types';
import AppLayout from '../components/layout/AppLayout';
import dynamic from 'next/dynamic';
import { trpc } from '../utils/trpc';
import { BulkOrderClientAPI } from '../db/types-client-api';
import { MAX_VALIDATION_MINUTES } from '../utils/constants';
import { useFlagBag } from '../flags/client';

const AppDynamicParts = dynamic(
  () => import('../components/layout/AppDynamicParts'),
  {
    ssr: false,
  }
);

interface Props {
  children: ReactNode | ReactNode[];
}

export const AppComponent = ({ children }: Props) => {
  const { flags, settled } = useFlagBag();
  const { isLoading, session, error, supabaseClient } = useSessionContext();
  const user = useUser();
  const router = useRouter();
  const refetchDictionary = useRef<any>({});

  const { data: locations } = trpc.useQuery(['locations'], {
    staleTime: 1000 * 60 * 60,
    ssr: true,
  });

  const {
    data: userProfile,
    isLoading: isUserProfileLoading,
    refetch: userProfileRefetch,
    isFetching,
    isRefetching,
  } = trpc.useQuery(['user-profile'], {
    staleTime: 1000 * 60 * 60,
    enabled: !!user,
    ssr: false,
  });

  refetchDictionary.current['user-profile'] = userProfileRefetch || null;

  const [wizardMode, setWizardMode] = useState<{
    mode?: WizardMode;
    topic?: any;
  } | null>(null);

  const openWizard = (mode: WizardMode, topic?: any) => {
    console.log('muly:openWizard', { mode, topic, router });
    const { flow, ...query } = router.query;

    const queryT = topic ? { ...query, topic } : query;

    router
      .push({ pathname: router.pathname, query: queryT }, undefined, {
        shallow: true,
      })
      .then(() => setWizardMode({ mode, topic }));
  };

  // useOpenWizardListener((mode?: WizardMode, topic?: string) => {
  //   console.log(`useOpenWizardListener`, { mode, topic, wizardMode });
  //   if ((wizardMode?.mode !== mode || wizardMode?.topic !== topic) && mode) {
  //     setWizardMode({ mode, topic });
  //   }
  // });

  // TODO: Should be moved to dynamic parts to save on initial load
  const onAction = async (action: Action, args?: any) => {
    // // signup timer should not work if already create order
    // if (action === Action.SignUpTimer) {
    //   if (wizardMode?.mode) {
    //     return;
    //   } else {
    //     action = Action.SignUp;
    //   }
    // }

    // if (
    //   !user &&
    //   action !== Action.SignUp &&
    //   action !== Action.CreateOrder &&
    //   action !== Action.ShowError
    // ) {
    //   action = Action.SignUp;
    // }

    if (action === Action.Close) {
      setWizardMode(null);
    } else if (action === Action.CreateFeed) {
      await router.push('/feed?new=true');
    } else if (action === Action.PlaceOrder) {
      const order: BulkOrderClientAPI = args;
      if (flags?.enableSquare) {
        window.open(order.squareUrl, '_blank', 'noopener,noreferrer');
      } else {
        const url = `/select-menu/${order.id}`;
        window.open(url, '_blank', 'noopener,noreferrer');
      }
    } else if (action === Action.Logout) {
      await supabaseClient.auth.signOut();
      refetch('orders-bulk');
      refetch('feed');
      refetch('orders-my');
      refetch('orders-social');
      // await router.replace('/');
      window.location.href = '/';
    } else {
      openWizard(action, args);
    }
  };

  const refetch = (refetchQuery: RefetchQuery) => {
    const refetchFn = refetchDictionary.current[refetchQuery];
    if (refetchFn) {
      refetchFn().then(() => {
        console.log(`RELOAD ${refetchQuery}`);
      });
    }
    // await refetch();
  };

  const loadingUserProfile = isLoading || isUserProfileLoading || isFetching;
  const dbContextValue: DBContextType = {
    loadingUserProfile: loadingUserProfile,
    userProfile: userProfile || undefined,
    locations: locations || [],
    onAction,
    refetch,
    setRefetch: (refetchQuery: RefetchQuery, refetch?: () => void) => {
      refetchDictionary.current[refetchQuery] = refetch || null;
    },
  };

  // console.log(`muly:AppComponent render`, {
  //   wizardMode,
  //   user,
  //   isLoading,
  //   userProfile,
  //   isUserProfileLoading,
  //   isFetching,
  //   isRefetching,
  //   loadingUserProfile,
  // });

  useEffect(() => {
    // console.log(`muly:AppComponent:useEffect, open wizard`, {
    //   isLoading,
    //   user,
    //   wizardMode,
    //   userProfile,
    //   isUserProfileLoading,
    // });
    // if (router.pathname !== '/') {
    //   console.log('muly:no user redirect to home', { router });
    //   router.push('/');
    // }
  }, [isLoading, user, wizardMode]);

  if (loadingUserProfile || !settled) {
    return null;
  }

  return (
    <DBContext.Provider value={dbContextValue}>
      <AppLayout
        userProfile={userProfile || null}
        onAction={onAction}
        loadingUserProfile={loadingUserProfile}
      >
        {children}
      </AppLayout>

      <AppDynamicParts
        mode={wizardMode?.mode!}
        topic={wizardMode?.topic}
        onClose={() => setWizardMode(null)}
        userProfile={userProfile}
        locations={locations || []}
      />
    </DBContext.Provider>
  );
};
